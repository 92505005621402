.swiper-lazy {
	transition: opacity $transition-hover ease;
	opacity: 0;

	&.swiper-lazy-loaded {
		opacity: 1;
	}
}

.image {
	transition: opacity $transition-hover ease;

	&.image-loading {
		opacity: 0;
	}

	&.bg {
		.imagewrap-image {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		&.contain {
			.imagewrap-image {
				background-size: contain;
			}
		}
	}
}