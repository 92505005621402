@keyframes spin {
	from {transform: translate(-50%, -50%) rotate(0deg);}
	to {transform: translate(-50%, -50%) rotate(360deg);}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}

@keyframes crossGradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes projectPreview {
	0% {
		background-position: top center;
	}
	5% {
		background-position: top center;
	}
	85% {
		background-position: bottom center;
	}
	90% {
		background-position: top center;
	}
}