.page.work-detail {

	.detail-backlink {
		@include font-bold;
		font-size: 1.8rem;
		line-height: 2.2rem;
		position: absolute;
		bottom: 100%;
		left: 0;
		margin-bottom: .5rem;
		display: inline-block;

		&:before {
			@include icon('arrow-left')
			font-size: .8em;
			margin-right: .5em;
		}

		opacity: 0;
		transition-delay: 600ms;
		transform: translate3d(0, .4rem, 0);
		transition:
			transform $transition-fast ease,
			opacity $transition-fast ease;
	}

	.detail-title {
		margin-bottom: .8rem;

		opacity: 0;
		transition-delay: 450ms;
		transform: translate3d(-.4rem, 0, 0);
		transition:
			transform $transition-fast ease,
			opacity $transition-fast ease;
	}

	.detail-info {
		margin-bottom: 1rem;
		line-height: 2rem;
		font-size: 1.6rem;

		opacity: 0;
		transition-delay: 300ms;
		transform: translate3d(-.4rem, 0, 0);
		transition:
			transform $transition-fast ease,
			opacity $transition-fast ease;

		> * {
			display: inline-block;
			margin-bottom: .2rem;

			+ * {
				&:before {
					@include font-regular;
					content: ', ';
				}
			}
		}

		.info-date {
			@include font-bold;
			margin-right: 1rem;
		}

		.info-link {
			@include font-medium;
			background-color: $color-text;
			color: $color-white;
			font-size: .7em;
			//background: $color-gray;
			border-radius: $radius-general;
			padding: 0 1rem;
			line-height: 2.2rem;
			box-shadow: $shadow-general;
			margin-top: -.4rem;
			display: inline-block;
			vertical-align: middle;
			//text-decoration: underline;
			&:before {
				display: none;
			}
		}
	}


	.detail-subinfo {
		display: flex;
		flex-flow: row wrap;
		margin-top: 2rem;

		.subinfo-section {
			max-width: calc(50% - 1.5rem);
			border-radius: $radius-general;
			padding: 1rem 1.5rem;
			background-color: $color-gray;
			box-shadow: $shadow-general;
			margin-right: 1.5rem;
			margin-bottom: 1rem;

			opacity: 0;
			transition-delay: 150ms;
			transform: translate3d(-.4rem, 0, 0);
			transition:
				transform $transition-fast ease,
				opacity $transition-fast ease;

			@include media {
				max-width: 100%;
				width: 100%;
				margin-right: 0;
			}
		}

			.section-title {
				display: inline-block;
				margin-right: 1rem;
				font-size: 1.1em;
			}

			.section-items {
				display: inline-block;
			}

				.items-item {
					display: inline-block;

					+ .items-item {

						&:before {
							content: ', ';
						}
					}
				}
	}

	.detail-preview {
		margin-top: 2rem;
		background-color: $color-gray;
		border-radius: $radius-general;
		box-shadow: $shadow-general;
		padding: 1.5rem;

		opacity: 0;
		transition-delay: 0ms;
		transform: translate3d(-.4rem, 0, 0);
		transition:
			transform $transition-fast ease,
			opacity $transition-fast ease;

		@include media {
			margin-top: 1rem;
		}

		&.active {
			&.shown {
				.preview-slideimage {
					.imagewrap-image {
						animation: none;
					}
				}
			}

		}

		.preview-innerwrap {
			position: relative;
			overflow: hidden;
			display: block;
			border-radius: $radius-general;
			padding-bottom: 60%;
			width: 100%;
			transition: padding-bottom $transition-anim ease;

			&:hover, &:active {
				.preview-extend {
					opacity: 1;
				}

				.preview-slideimage {
					opacity: .4;
				}
			}
		}

		.preview-slideimage {
			position: absolute;
			height: 100%;
			width: 100%;
			transition: opacity $transition-hover ease;
			z-index: 2;

			.imagewrap-image {
				background-position: top center;
				transition: background-position $transition-hover ease;
				animation: projectPreview 20s infinite;
			}
		}

		.preview-extendimage {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1;
			pointer-events: none;
			opacity: 0;
			transition: opacity $transition-hover ease;
		}

		.preview-extend {
			position: absolute;
			font-size: 3rem;
			opacity: 0;
			top: 50%;
			left: 50%;
			z-index: 3;
			transform: translate3d(-50%, -50%, 0);
			transition: opacity $transition-hover ease;
		}
	}

	&.show {
		.detail-backlink {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition-delay: 0ms;
			transition-duration: $transition-anim;			
		}

		.detail-title {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition-delay: 150ms;
			transition-duration: $transition-anim;			
		}

		.detail-info {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition-delay: 300ms;
			transition-duration: $transition-anim;			
		}

		.detail-description {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition-delay: 450ms;
			transition-duration: $transition-anim;
		}

		.detail-subinfo {

			.subinfo-section {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				transition-delay: 600ms;
				transition-duration: $transition-anim;

				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						transition-delay: (525 + ($i*75)) * 1ms;
					}
				}
			}		
		}

		.detail-preview {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition-delay: 750ms;
			transition-duration: $transition-anim;			
		}
	}
}