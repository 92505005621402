@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon.eot?1');
	src: url('../fonts/icomoon.eot?1#iefix') format('embedded-opentype'),
		url('../fonts/icomoon.ttf?1') format('truetype'),
		url('../fonts/icomoon.woff?1') format('woff'),
		url('../fonts/icomoon.svg?1#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@mixin icon($name, $type: 'self') {
	@if $type=='before' {
		&:before {
			@extend #{'.icon-' + $name + ':before'} !optional;
			@extend %icomoon;
		}
	}

	@else if $type=='after' {
		&:after {
			@extend #{'.icon-' + $name + ':before'} !optional;
			@extend %icomoon;
		}
	}

	@else {
		@extend #{'%icon-' + $name} !optional;
		@extend %icomoon;
	}
}

%icomoon,
[class^="icon-"],
[class*=" icon-"] {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%icon-zoom-out {
	content: "\e901";
}

	.icon-zoom-out:before {
		@extend %icon-zoom-out;
	}

%icon-zoom-in {
	content: "\e902";
}

	.icon-zoom-in:before {
		@extend %icon-zoom-in;
	}

%icon-arrow-left {
	content: "\e900";
}

	.icon-arrow-left:before {
		@extend %icon-arrow-left;
	}

%icon-facebook {
	content: "\f09a";
}

	.icon-facebook:before {
		@extend %icon-facebook;
	}

%icon-mail {
	content: "\f0e0";
}

	.icon-mail:before {
		@extend %icon-mail;
	}

%icon-linkedin {
	content: "\f0e1";
}

	.icon-linkedin:before {
		@extend %icon-linkedin;
	}

%icon-github {
	content: "\f113";
}

	.icon-github:before {
		@extend %icon-github;
	}