.page.home {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition-delay: 250ms;
	transform: translate3d(0, -1rem, 0);
	transition:
		opacity $transition-anim ease,
		transform $transition-anim ease;

	@include media {
		padding: 3rem;
	}

	.home-logo {
		width: 30rem;
	}

	.home-menu {
		font-size: 1.8rem;
		margin-top: 2rem;
		padding-top: 2rem;
		border-top: .2rem solid $color-text;
		display: flex;
		justify-content: space-between;
		opacity: 0;
		transform: translate3d(0, -1rem, 0);
		transition:
			opacity $transition-anim ease,
			transform $transition-anim ease;
	}

	&.show {
		opacity: 1;
		transition-delay: 0;
		transform: translate3d(0, 0, 0);

		.home-menu {
			transition-delay: 250ms;
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
}