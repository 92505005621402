$color-black: #000;
$color-white: #fff;

$color-primary: #002333;

$color-bg: #efefef;
$color-bg-dark: $color-primary;

$color-text: $color-primary;

$color-gray: #f3f3f3;

// Base Colors

// Font Weighting
$weight-extralight: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 600;
//$weight-semibold: 600;
$weight-bold: 700;
$weight-black: 800;

$fontweight-heading: $weight-bold;
$fontweight-bold: $weight-bold;

$darken-ratio-low: 4%;
$darken-ratio: 6%;

//transition
$transition-superfast: 80ms;
$transition-fast: 180ms;
$transition-hover: 250ms;
$transition-anim-fast: 300ms;
$transition-anim: 400ms;
$transition-long: 600ms;

//radiusses
$radius-general: .4rem;
$radius-big: .6rem;

//easing
$ease-1: "cubic-bezier(0.32, 0.28, 0, 0.97)";

//shadows
$shadow-general: #{"0rem .5rem 1.0rem 0rem rgba(0, 0, 0, 0.05)"};
$shadow-general-dark: #{"0rem .5rem 1.0rem 0rem rgba(0, 0, 0, 0.1)"};
$shadow-wide: #{"0rem .6rem 2.4rem 0rem rgba(0, 0, 0, 0.1)"};
$shadow-input: #{"0rem .1rem .5rem 0rem rgba(0, 0, 0, 0.12)"};